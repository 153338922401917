import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/source/contexts/DrawerContext';

import { ResetCSS } from 'common/source/assets/css/style';
import { theme } from 'common/source/styles/theme';
import { GlobalStyle, ContentWrapper } from 'common/source/styles';
import Navbar from 'common/source/containers/Navbar';
import Banner from 'common/source/containers/Banner';
// import BlogSection from 'common/source/containers/BlogSection';
// import TimelineSection from 'common/source/containers/TimelineSection';
// import ContactSections from 'common/source/containers/Contact';
// import PartnerSection from 'common/source/containers/Partner';
// import FeatureSection from 'common/source/containers/FeatureSection';
import Footer from 'common/source/containers/Footer';
// import Container from 'common/source/components/UI/Container';
import ProductList from 'common/source/containers/ProductList';

import SEO from '../../components/SEO';
import config from '../../../data/SiteConfig';

const PATHS = [
  {
    order: 1,
    title: 'Productos',
    slug: '/productos'
  },
  {
    order: 2,
    title: 'Arquitectural',
    slug: '/productos/arquitectural'
  }
];

class ProductosPageArquitectural extends React.Component {
  render() {
    const { data } = this.props;
    const { pageData, allProducts } = data;
    const pageProducts = pageData.edges;
    const allProductsForSearch = allProducts.edges;
    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <SEO pageTitle={`Arquitectural | ${config.siteTitle}`} path="/productos/arquitectural" />
          <ResetCSS />
          <GlobalStyle />

          <ContentWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar />
              </DrawerProvider>
            </Sticky>
            <Banner heading="Arquitectural">Productos línea arquitectónica</Banner>
            <ProductList
              paths={PATHS}
              title="Arquitectural –"
              products={pageProducts}
              allProducts={allProductsForSearch}
            />

            <Footer />
          </ContentWrapper>
        </Fragment>
      </ThemeProvider>
    );
  }
}
export default ProductosPageArquitectural;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query ProductosPageArquitecturalQuery {
    pageData: allMarkdownRemark(
      limit: 2000
      filter: {
        frontmatter: {
          type: { eq: "PRODUCT" }
          published: { eq: true }
          catalog: { in: ["arquitectural"] }
        }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            slug
            type
            date
            description
            thumbnail
          }
        }
      }
    }
    allProducts: allMarkdownRemark(
      limit: 2000
      filter: { frontmatter: { type: { eq: "PRODUCT" }, published: { eq: true } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          frontmatter {
            title
            slug
            description
            thumbnail
          }
        }
      }
    }
  }
`;
